import Vuex from "vuex";
import Vue from "vue";
import moduleResource from "./resource";
import { fetchUesrInfo } from "@/request/authRequest";
const SET_DATA = "setData";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    userInfo: JSON.parse(localStorage.userInfo || "{}"),
    connectAddr: "",
    alertInfo: {},
  },
  getters: {
    walletObj(state) {
      const { walletType } = state.userInfo.wallet || {};
      let provider = window.ethereum;
      let metamaskProvider,
        coinbaseProvider,
        okxProvider = null;

      if (window.ethereum.providers?.length) {
        window.ethereum.providers.forEach(async (p) => {
          if (p.isCoinbaseWallet) {
            coinbaseProvider = p;
          }
          if (p.isMetaMask) {
            metamaskProvider = p;
          }
          if (p.isOkx) {
            okxProvider = p;
          }
        });
        switch (walletType) {
          case "COINBASE":
            provider = coinbaseProvider;
            break;
          case "OKX":
            provider = okxProvider;
            break;
          default:
            provider = metamaskProvider;
            break;
        }
      }

      return provider;
    },
  },
  mutations: {
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_CONNECT_ADDR(state, addr) {
      state.connectAddr = addr;
    },
    [SET_DATA](state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      try {
        const { data } = await fetchUesrInfo();
        localStorage.userInfo = JSON.stringify(data);
        commit("SET_USERINFO", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getWalletAccount({ getters, commit }) {
      try {
        const accounts = await getters.walletObj.request({
          method: "eth_requestAccounts",
        });
        commit("SET_CONNECT_ADDR", accounts[0]);
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
    moduleResource,
  },
});

export const setState = (Vue.prototype.$setState = (data) => {
  store.commit(SET_DATA, data);
});

export default store;
