import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/",
    redirect: "/overview",
    name: "Layout",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/overview",
        name: "overview",
        component: () => import("@/views/overview/overview"),
      },
      {
        path: "/success/:txHash",
        name: "success",
        component: () => import("@/views/success/success"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.token;
  if (to.path == "/login") {
    if (token) return next("/");
    next();
  } else {
    if (!token) return next("/login");
    next();
  }
});

export default router;
