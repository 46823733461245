import HttpRequest from "..";

let token = localStorage.getItem("token");
const authRequest = new HttpRequest({
  baseURL: process.env.VUE_APP_AUTH_URL,
  interceptor: {
    requestInterceptor(config) {
      if (config.headers && token) {
        config.headers.Authorization = "Bearer " + token;
      }
      return config;
    },
  },
});

// export default authRequest;
export const walletLogin = async (accounts, data) => {
  accounts = accounts.toLowerCase();
  const res = await authRequest.post({
    url: `/web3login/${accounts}`,
    data,
  });
  return res.data.stoken;
};

export const fetchSsoLogin = async (stoken) => {
  return authRequest.post({
    url: `/st/${stoken}`,
  });
};
export const fetchUesrInfo = async () => {
  return await authRequest.get({
    url: "/user",
  });
};
