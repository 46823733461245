import HttpRequest from "..";

let token = localStorage.getItem("token");
const resourceRequest = new HttpRequest({
  baseURL: process.env.VUE_APP_BILL_CONSUME_URL,
  interceptor: {
    requestInterceptor(config) {
      if (config.headers && token) {
        config.headers.Authorization = token;
      }
      return config;
    },
  },
});

export const fetchPrice = async () => {
  return await resourceRequest.get({
    url: "/common/price",
  });
};
