import axios from "axios";

export default class HttpRequest {
  // instance: AxiosInstance;
  // interceptor?: IInterceptors;
  constructor(config) {
    this.instance = axios.create(config);
    this.interceptor = config.interceptor;
    // construct interceptors
    this.instance.interceptors.request.use(
      this.interceptor?.requestInterceptor,
      this.interceptor?.requestInterceptorErr
    );
    this.instance.interceptors.response.use(
      this.interceptor?.responseInterceptor,
      this.interceptor?.responseInterceptorErr
    );

    // global interceptors
    this.instance.interceptors.request.use(
      (config) => config,
      (error) => error
    );
    this.instance.interceptors.response.use(
      (data) => {
        if (data.data.code == 401) {
          localStorage.clear();
          location.reload();
        }
        return data;
      },
      (error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          location.reload();
        }
        return error;
      }
    );
  }
  request(config) {
    return new Promise((resolve, reject) => {
      if (config.interceptor?.requestInterceptor) {
        config = config.interceptor.requestInterceptor(config);
      }
      this.instance
        .request(config)
        .then((res) => {
          if (config.interceptor?.responseInterceptor) {
            res = config.interceptor.responseInterceptor(res);
          }
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  get(config) {
    return this.request({ ...config, method: "GET" });
  }
  post(config) {
    return this.request({ ...config, method: "POST" });
  }
  put(config) {
    return this.request({ ...config, method: "PUT" });
  }
  delete(config) {
    return this.request({ ...config, method: "DELETE" });
  }
}
