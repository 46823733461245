<template>
  <v-app>
    <router-view />
    <e-alert />
  </v-app>
</template>

<script>
import EAlert from "./components/e-alert.vue";
export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    EAlert,
  },
};
</script>
